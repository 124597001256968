import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames';

import { useEmailValidation } from 'src/hooks';
import { useUserContext } from 'src/context/useUserContext';
import { Button, Checkbox, Input } from 'src/components';
import { IAuthForm } from 'src/consts/types';
import { login } from 'src/requests/auth';
import { setRoles, setTokens } from 'src/utils/token';
import { LockIcon, PersonIcon } from 'src/assets/icons';
import { useIsMobile } from 'src/hooks/useIsMobile';
import classes from './styles.module.scss';

const LoginPage = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { getUser } = useUserContext();

  const [form, setForm] = useState<IAuthForm>({
    email: '',
    password: '',
  });
  const [isEmailValid, validateEmail] = useEmailValidation();
  const [loading, setLoading] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    if (name === 'email') {
      validateEmail(value);
    }
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleButtonClick = (): void => {
    setLoading(true);
    login(form)
      .then((res) => {
        if (res.success) {
          setTokens(res.accessToken, res.refreshToken);
          setRoles(res.roles);
          getUser();
          if (isMobile) {
            navigate('/dashboard');
          } else {
            navigate('/');
          }
        }
      })
      .catch(({ response }) => {
        toast.error(response.data.msg || 'invalid-login-or-password');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleButtonClick();
    }
  };

  const handleCheckboxChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <div className={classes['login']}>
      <div className={classes['login__wrapper']}>
        <h1 className={classNames(classes['login__title'])}>Вход в систему</h1>
        <div className={classes['login__inside']}>
          <Input
            isValid={isEmailValid}
            placeholder={'Email'}
            className={classes['email']}
            name={'email'}
            value={form.email}
            onChange={onChange}
            errorMsg={'Неверный формат email'}
            onKeyDown={handleKeyDown}
            icon={PersonIcon}
          />
          <div className={classes['login__line']}></div>
          <Input
            placeholder={'Пароль'}
            className={classes['password']}
            name={'password'}
            value={form.password}
            onChange={onChange}
            type={'password'}
            onKeyDown={handleKeyDown}
            icon={LockIcon}
          />
          <Button
            name={'Войти'}
            variant="auth"
            onClick={handleButtonClick}
            className={classes['button']}
            disabled={!form.email.length || !form.password.length || !isEmailValid}
          />
        </div>
        <div className={classes['login__footer']}>
          <Checkbox label="Запомнить меня" checked={checked} onChange={handleCheckboxChange} />
          <Link to={'/forgotPassword'} className={classes['login__link']}>
            Забыли пароль?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
