import React from 'react';
import classes from './styles.module.scss';

interface ICheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const Checkbox: React.FC<ICheckboxProps> = ({ label, ...rest }) => {
  const randomId = React.useId();
  // TODO: Implement the checkbox component

  return (
    <label
      htmlFor={rest.id ?? randomId}
      className={classes['checkbox']}
      style={{ paddingLeft: label ? '35px' : '20px' }}
    >
      {label}
      <input id={rest.id ?? randomId} type="checkbox" {...rest} />
      <span className={classes['checkbox__checkmark']}></span>
    </label>
  );
};

export default Checkbox;
