import React, { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import Icon from '../../components/icon';
import { Button, Checkbox } from '../../components';
import { ICompany, IMessage, IResponse, ISource } from '../../consts/types';
import { archiveMessage, getMessageList } from '../../requests/message';
import Select from '../../components/select';
import { getCompanyList, getCompanySourceList } from '../../requests/source';
import { findColorById, getShortName } from '../../utils/helpers';
import Pagination from '../../components/pagination';
import Loader from '../../components/loader';
import classes from './styles.module.scss';
import MessageView from './message-view';

const Messages: React.FC = () => {
  const pageSize = 10;
  const [page, setPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedMessage, setSelectedMessage] = useState<IMessage | null>(null);
  const [list, setList] = useState<IMessage[]>([]);
  const [totalItems, setTotalItems] = useState<number>();

  const [companyList, setCompanyList] = useState<ICompany[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<ICompany[]>([]);

  const [sourcesList, setSourcesList] = useState<ISource[]>([]);
  const [selectedSources, setSelectedSources] = useState<ISource[]>([]);

  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [checked, setChecked] = useState<Record<number, boolean>>({});

  const [status, setStatus] = useState<'ACTIVE' | 'ARCHIVE'>('ACTIVE');
  const [didMount, setDidMount] = useState(false);

  const getParams = () => {
    const sourceId = selectedSources.map((source) => source.id).join(',');
    return {
      sourceId,
      status,
      page,
      pageSize,
    };
  };

  const handleGetCompanyList = () => {
    getCompanyList()
      .then((res) => {
        const compList = res.list.map((item) => {
          return {
            ...item,
            id: item.id,
            name: getShortName(item.name),
            label: item.name,
            listType: 'company',
            columnName: 'Предприятия',
            backgroundColor: findColorById(item.id),
          };
        });
        setCompanyList(compList);
      })
      .catch(() => null);
  };

  const handleGetMessages = () => {
    setIsLoading(true);
    const params = getParams();
    getMessageList(params)
      .then((res) => {
        setList(res.list);
        setTotalItems(res.totalSize);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  useEffect(() => {
    handleGetCompanyList();
  }, []);

  const handleCheckMessage =
    (messageId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked((prevState) => ({ ...prevState, [messageId]: event.target.checked }));
    };

  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckAll(event.target.checked);
  };

  useEffect(() => {
    list.forEach((message) => {
      setChecked((prevState) => ({ ...prevState, [message.id]: checkAll }));
    });
  }, [checkAll]);

  const handleGetSourceList = () => {
    const ids = selectedCompanies.map((company) => company.id);
    getCompanySourceList(ids)
      .then((res) => {
        setSourcesList(
          res.list
            .map((item) => ({ ...item, label: item.name, name: item.name }))
            .sort((a, b) => a.name.localeCompare(b.name)),
        );
      })
      .catch(() => null);
  };

  useEffect(() => {
    if (selectedCompanies?.length > 0) {
      handleGetSourceList();
    }
  }, [selectedCompanies]);

  useEffect(() => {
    if (page !== 0) {
      setPage(0);
    } else {
      handleGetMessages();
    }
  }, [selectedSources]);

  useEffect(() => {
    if (didMount) {
      handleGetMessages();
    }
  }, [page, status]);

  useEffect(() => {
    setDidMount(true);
  }, []);

  const checkedMessages = useMemo(() => {
    return Object.entries(checked)
      .filter(([, value]) => value)
      .map(([id]) => +id);
  }, [checked]);

  const handleChangeStatus = () => {
    setPage(0);
    setStatus(status === 'ACTIVE' ? 'ARCHIVE' : 'ACTIVE');
  };

  const handleArchive = (messageId: number) => {
    setIsLoading(true);
    archiveMessage(messageId)
      .then(() => {
        if (page !== 0 && list.length === 1) {
          setPage(0);
        } else {
          handleGetMessages();
        }
      })
      .catch(() => setIsLoading(false));
  };

  const handleArchiveChecked = () => {
    setIsLoading(true);
    const promises: Promise<IResponse<null>>[] = [];
    checkedMessages.map((messageId) => {
      promises.push(archiveMessage(messageId));
    });
    Promise.all(promises)
      .then(() => {
        setIsLoading(false);
        setPage(0);
        setChecked({});
        setCheckAll(false);
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <>
      <Loader isLoading={isLoading}>
        <div className={classes['messages']}>
          <div className={classes['messages__header']}>
            <div className={classes['messages__header__title']}>Мои сообщения</div>
            <div className={classes['messages__header__search']}>
              <input
                type={'search'}
                placeholder={'Поиск'}
                value={searchValue}
                onChange={({ target }) => setSearchValue(target.value)}
              />
              <Icon name={'Search'} size={16} />
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <Checkbox checked={checkAll} onChange={handleCheckAll} disabled={!list.length} />
            <Select
              sources
              label="Предприятия"
              options={companyList}
              handleSelect={setSelectedCompanies}
              selectedOptions={selectedCompanies}
              isMultiple={true}
              maxLength={companyList.length}
            />
            <Select
              sources
              label="Источники"
              options={sourcesList}
              handleSelect={setSelectedSources}
              selectedOptions={selectedSources}
              isMultiple={true}
              maxLength={5}
            />
          </div>
          <table className={classes['messages__table']}>
            <tbody>
              {list.map((message) => (
                <tr key={message.id} onDoubleClick={() => setSelectedMessage(message)}>
                  <td>
                    <Checkbox
                      checked={checked[message.id] ?? false}
                      onChange={handleCheckMessage(message.id)}
                    />
                  </td>
                  <td>{message.sender}</td>
                  <td>{message.header}</td>
                  <td>
                    {message.createDate &&
                      format(new Date(message.createDate), 'dd MMMM', { locale: ru })}
                  </td>
                  {status === 'ACTIVE' && (
                    <td>
                      <button
                        type="button"
                        className={classes['messages__transparentBtn']}
                        onClick={() => handleArchive(message.id)}
                      >
                        <Icon name={'Archive'} />
                      </button>
                    </td>
                  )}
                </tr>
              ))}
              {!list.length && (
                <tr>
                  <td className={classes['messages__table__notFound']}>
                    <span>Нет сообщений</span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <div className={classes['messages__footer']}>
            <Button
              name={status === 'ACTIVE' ? 'Архив' : 'Входящие'}
              variant={'rounded'}
              onClick={handleChangeStatus}
              disabled={isLoading}
            />
            <div>
              <Pagination page={page} setPage={setPage} totalItems={totalItems} limit={pageSize} />
              {checkedMessages.length > 0 && (
                <Button
                  className={classes['messages__archiveBtn']}
                  name="Архивировать"
                  variant={'rounded'}
                  onClick={handleArchiveChecked}
                  disabled={isLoading}
                />
              )}
            </div>
          </div>
        </div>
      </Loader>
      <MessageView
        show={!!selectedMessage?.id}
        close={() => setSelectedMessage(null)}
        message={selectedMessage as IMessage}
      />
    </>
  );
};

export default Messages;
