import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';

import { DiagramCompanyType, DiagramDivisionType, IDateSelection } from 'src/consts/types';
import { findColorById, getShortName } from 'src/utils/helpers';
import { dashboardDiagram } from 'src/requests/dashboard';
import DiagramTable from '../../components/carddiagram/components/diagram-table-2';
import { Button } from '../../components';
import { externalTooltipHandler } from '../../utils/diagram';
import RangePicker from '../../components/range-picker';
import classes from './styles.module.scss';

const EmissionChart: React.FC = () => {
  const { type } = useParams();
  const navigate = useNavigate();
  const chartType = type as 'company' | 'division';
  const [list, setList] = useState<Record<string, DiagramCompanyType[] | DiagramDivisionType[]>>({
    GAS: [],
    DUST: [],
  });
  const currentDate = {
    key: 'selection',
    startDate: new Date(),
    endDate: new Date(),
  };
  const [range, setRange] = useState<IDateSelection>(currentDate);

  const getData = (data: DiagramCompanyType[] | DiagramDivisionType[]) => {
    const listData = data.map((item) => item.params.reduce((acc, param) => acc + param.value, 0));
    const isEmpty = !listData?.length || listData.every((value) => value === 0);
    return isEmpty
      ? {
          labels: [''],
          datasets: [
            {
              label: '',
              data: [100],
              backgroundColor: ['#F2EEE8'],
            },
          ],
        }
      : {
          labels: data.map((item) => item.name),
          datasets: [
            {
              label: '',
              data: listData,
              backgroundColor: data.map((item) => findColorById(item.id)),
              borderColor: data.map((item) => findColorById(item.id)),
              borderWidth: 1,
            },
          ],
        };
  };

  const getOptions = (
    data: DiagramCompanyType[] | DiagramDivisionType[],
  ): ChartOptions<'doughnut'> => {
    const listData = data.map((item) => item.params.reduce((acc, param) => acc + param.value, 0));
    const isEmpty = !listData?.length || listData.every((value) => value === 0);
    return {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: (context) => {
            const foundList = data
              ? (data as DiagramCompanyType[])?.find(
                  (item: DiagramCompanyType) =>
                    item?.name === context?.tooltip?.dataPoints?.[0]?.label,
                )
              : null;

            const filteredData = foundList?.params
              ?.map((item) => ({
                name: item?.title,
                value: item?.value,
              }))
              .filter((item) => item.name);

            filteredData && externalTooltipHandler(context, filteredData);
          },
        },
        datalabels: {
          color: '#fff',
          textStrokeColor: '#000',
          font: {
            size: 14,
          },
          backgroundColor: (context) => {
            const backgroundColor = context.dataset.backgroundColor as string[];
            const colorIndex = context.dataIndex;
            const color = backgroundColor[colorIndex];
            return color && !isEmpty ? `${color}CC` : 'rgba(0, 0, 0, 0.8)'; // 'CC' adds 0.8 opacity in hex
          },
          borderColor: 'white',
          borderRadius: 25,
          borderWidth: 1,
          padding: {
            top: 8,
            right: 4,
            bottom: 8,
            left: 4,
          },
          formatter: (value, context) => {
            const total = context.dataset.data.reduce(
              (sum, val) => (sum as number) + (typeof val === 'number' ? val : 0),
              0,
            );
            return isEmpty
              ? 'Нет данных'
              : value
                ? ((value / (total as number)) * 100).toFixed(1) + '%'
                : null;
          },
        },
      },
    };
  };

  const chartName = useMemo(() => {
    return chartType === 'company' ? 'Предприятия' : 'Дивизионы';
  }, [chartType]);

  const handleGetDashboardDiagram = (emissionType: 'GAS' | 'DUST') => {
    const date = { from: range.startDate, to: range.endDate };
    dashboardDiagram(chartType, date, emissionType).then((res) => {
      const mappedList: DiagramCompanyType[] | DiagramDivisionType[] = res.map(
        (item: DiagramCompanyType | DiagramDivisionType) => {
          return {
            ...item,
            id: item.id,
            name: chartType === 'company' ? getShortName(item.name) : item.name,
            label: item.name,
            listType: chartType,
            columnName: chartName,
          };
        },
      );
      setList((prevState) => ({ ...prevState, [emissionType]: mappedList }));
    });
  };

  useEffect(() => {
    handleGetDashboardDiagram('GAS');
    handleGetDashboardDiagram('DUST');
  }, [range]);

  return (
    <div className={classes['emissionChart']}>
      <div className={classes['emissionChart__grid']}>
        <div className={classes['emissionChart__header']}>
          <RangePicker onChange={(value) => setRange(value)} />
        </div>
        <div className={classes['emissionChart__card']}>
          <h1 className={classes['emissionChart__card__title']}>Газообразные вещества</h1>
          <div className={classes['emissionChart__row']}>
            <div>
              <Doughnut data={getData(list.GAS)} options={getOptions(list.GAS)} />
            </div>
            <div>
              <DiagramTable list={list.GAS} itemNameWidth={'90px'} />
            </div>
          </div>
        </div>
        <div className={classes['emissionChart__card']}>
          <h1 className={classes['emissionChart__card__title']}>Твердые вещества</h1>
          <div className={classes['emissionChart__row']}>
            <div>
              <Doughnut data={getData(list.DUST)} options={getOptions(list.DUST)} />
            </div>
            <div>
              <DiagramTable list={list.DUST} itemNameWidth={'90px'} />
            </div>
          </div>
        </div>
      </div>
      <div className="df jc-c">
        <Button name={chartName} variant={'rounded'} onClick={() => navigate('/dashboard')} />
      </div>
    </div>
  );
};

export default EmissionChart;
