import React, { useEffect, useState } from 'react';
import Diagram from 'src/components/carddiagram';
import {
  DiagramCompanyType,
  DiagramDivisionType,
  DiagramEmissionType,
  IDateSelection,
  ISource,
} from 'src/consts/types';
import { getShortName } from 'src/utils/helpers';
import { dashboardDiagram, getEmissionType } from 'src/requests/dashboard';
import DashboardBarChart from './components/DashboardBarChart';
import { getAllSources } from 'src/requests/source';
import styles from './styles.module.scss';

const Dashboard: React.FC = () => {
  const [list, setList] = useState<{
    companyList: DiagramCompanyType[];
    divisionList: DiagramDivisionType[];
    emissionList: DiagramEmissionType[];
  }>({
    companyList: [],
    divisionList: [],
    emissionList: [],
  });
  const currentDate = {
    key: 'selection',
    startDate: new Date(),
    endDate: new Date(),
  };
  const [companyDate, setCompanyDate] = useState<IDateSelection>(currentDate);
  const [divisionDate, setDivisionDate] = useState<IDateSelection>(currentDate);
  const [emissionDate, setEmissionDate] = useState<IDateSelection>(currentDate);
  const [selectedSources, setSelectedSources] = useState<ISource[]>([]);
  const [sourcesList, setSourcesList] = useState<ISource[]>([]);

  useEffect(() => {
    const date = { from: companyDate.startDate, to: companyDate.endDate };
    dashboardDiagram('company', date).then((res) => {
      const companyList: DiagramCompanyType[] = res.map((item: DiagramCompanyType) => {
        return {
          ...item,
          id: item.id,
          name: getShortName(item.name),
          label: item.name,
          listType: 'company',
          columnName: 'Предприятия',
        };
      });
      setList((prev) => ({
        ...prev,
        companyList,
      }));
    });
  }, [companyDate]);

  useEffect(() => {
    const date = { from: divisionDate.startDate, to: divisionDate.endDate };
    dashboardDiagram('division', date).then((data) => {
      const divisionList = data.map((item: DiagramDivisionType) => {
        return {
          ...item,
          id: item.id,
          name: item.name,
          label: item.name,
          listType: 'division',
          columnName: 'Дивизионы',
        };
      });

      setList((prev) => ({
        ...prev,
        divisionList,
      }));
    });
  }, [divisionDate]);

  useEffect(() => {
    const sources = selectedSources.map((item) => item.id);
    const date = { from: emissionDate.startDate, to: emissionDate.endDate };
    getEmissionType(date, sources && sources).then((data) => {
      const emissionList = data.map((item: DiagramDivisionType, index: number) => {
        return {
          ...item,
          id: index + 1,
          name: item.name,
          label: item.name,
          listType: 'emission',
          columnName: 'Загрязняющие вещества',
        };
      });

      setList((prev) => ({
        ...prev,
        emissionList,
      }));
    });
  }, [emissionDate, selectedSources]);

  useEffect(() => {
    getAllSources().then((res) => {
      setSourcesList(res.list.map((item) => ({ ...item, label: item.name, name: item.name })));
    });
  }, []);

  useEffect(() => {
    selectedSources.length === 0 && setSelectedSources(sourcesList);
  }, [sourcesList]);

  return (
    <>
      <div className={styles['dashboard__cards']}>
        <Diagram
          name={'Предприятия'}
          listType={'company'}
          list={list.companyList}
          itemNameWidth={'90px'}
          onDateChange={(range) => setCompanyDate(range)}
        />
        <Diagram
          name={'Дивизионы'}
          listType={'division'}
          list={list.divisionList}
          itemNameWidth={'120px'}
          onDateChange={(range) => setDivisionDate(range)}
        />
        <Diagram
          name={'Загрязняющие вещества'}
          listType={'emission'}
          list={list.emissionList}
          sourceList={sourcesList}
          selectedSources={selectedSources}
          setSelectedSources={setSelectedSources}
          itemNameWidth={'60px'}
          onDateChange={(range) => setEmissionDate(range)}
        />
      </div>
      <DashboardBarChart />
    </>
  );
};

export default Dashboard;
