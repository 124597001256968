import React, { useState, useEffect, useRef } from 'react';
import classes from './styles.module.scss';
import classNames from 'classnames';
import { ArrowBlack, CloseIcon, WhiteClose } from 'src/assets/icons';
import Checkbox from '../checkbox';

interface ISelectProps {
  options: any[] | undefined;
  label?: string;
  secondLabel?: string;
  handleSelect: (option: any) => void;
  isMultiple?: boolean;
  maxLength?: number;
  selectedOptions: any[];
  handleChangeList?: (type: string) => void;
  listType?: string;
  placeholder?: string;
  sources?: boolean;
  contentSize?: number;
  minWidth?: string;
  minHeight?: string;
  right?: boolean;
}

const Select: React.FC<ISelectProps> = ({
  options,
  label,
  secondLabel,
  handleSelect,
  selectedOptions,
  maxLength = 1,
  isMultiple = false,
  handleChangeList,
  listType,
  placeholder = 'Выберите',
  sources,
  minWidth = 'auto',
  minHeight = 'auto',
  contentSize = 3,
  right = false,
}) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const optionsRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLDivElement>(null);

  const handleSelectOption = (option: any) => {
    if (isMultiple) {
      handleSelect(
        selectedOptions.some((item) => item.id === option.id)
          ? selectedOptions.filter((item) => item.id !== option.id)
          : [...selectedOptions, option],
      );
    } else {
      handleSelect([option]);
    }
  };

  const handleDeselectOption = (option?: any) => {
    if (option) {
      const filtered = selectedOptions.filter((item) => item.id !== option.id);
      handleSelect(filtered);
    } else {
      handleSelect([]);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      optionsRef.current &&
      !optionsRef.current.contains(event.target as Node) &&
      selectRef.current &&
      !selectRef.current.contains(event.target as Node)
    ) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={classNames(classes['select'])}>
      {label && (
        <div className={classes['select__label']}>
          <span
            onClick={() => {
              handleChangeList && handleChangeList('company');
            }}
            className={`${listType === 'company' && classes['active-label']}`}
          >
            {label && label}
          </span>
          {secondLabel && '  /  '}
          <span
            onClick={() => {
              handleChangeList && handleChangeList('division');
            }}
            className={`${listType === 'division' && classes['active-label']}`}
          >
            {secondLabel && secondLabel}
          </span>
        </div>
      )}
      <div
        className={classes['select__wrapper']}
        onClick={() => setShowOptions((prev) => !prev)}
        ref={selectRef}
        style={{
          minWidth,
          minHeight,
        }}
      >
        <div
          className={classes['select__grid']}
          style={{
            gridTemplateColumns: `repeat(${contentSize}, 1fr)`,
          }}
        >
          {selectedOptions.length > 0 && !sources ? (
            selectedOptions.map((item) => (
              <div
                key={item?.id}
                className={classes['select__selected']}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <p>{item?.name}</p>
                {isMultiple && (
                  <img src={CloseIcon} alt="close" onClick={() => handleDeselectOption(item)} />
                )}
              </div>
            ))
          ) : sources && selectedOptions.length > 0 ? (
            <div
              className={classes['select__selected']}
              style={{
                paddingLeft: '20px',
                paddingRight: '20px',
                background: '#6496FB',
                color: 'white',
                gap: '15px',
                minHeight: '38px',
                minWidth: '72px',
              }}
            >
              <p
                style={{
                  fontSize: '20px',
                  fontWeight: 600,
                  lineHeight: '24px',
                }}
              >
                {selectedOptions.length}
              </p>
              {isMultiple && (
                <img src={WhiteClose} alt="close" onClick={() => handleDeselectOption()} />
              )}
            </div>
          ) : (
            <span
              style={{
                height: '44px',
                lineHeight: '44px',
              }}
              className={classes['placeholder']}
            >
              {placeholder}
            </span>
          )}
        </div>
        <img src={ArrowBlack} alt="ArrowBlack" />
      </div>
      {showOptions && (
        <div
          className={classes['select__options']}
          ref={optionsRef}
          style={{
            left: right ? 'auto' : 0,
            right: right ? 0 : 'auto',
          }}
        >
          {options &&
            options.map((option) => (
              <li
                style={{
                  userSelect: 'none',
                }}
                className={classNames(
                  classes[`option--${!!selectedOptions.find((item) => item.id === option.id)}`],
                )}
                key={option.id}
              >
                <Checkbox
                  id={option.id}
                  label={option?.label}
                  checked={!!selectedOptions.find((item) => item.id === option.id)}
                  onChange={() => handleSelectOption(option)}
                  disabled={
                    !selectedOptions.find((item) => item.id === option.id) &&
                    selectedOptions.length >= maxLength
                  }
                />
              </li>
            ))}
        </div>
      )}
    </div>
  );
};

export default Select;
