import React from 'react';
import classNames from 'classnames';
import classes from './styles.module.scss';

interface IProps {
  isLoading?: boolean;
  children?: React.ReactNode;
}

const Loader: React.FC<IProps> = ({ isLoading, children }) => {
  return (
    <div className={classNames(classes['loader'], isLoading && classes['loader--active'])}>
      {isLoading && <div className={classes['loader__ring']} />}
      {children}
    </div>
  );
};

export default Loader;
