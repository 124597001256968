import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { LoginPage, ForgotPassword, ResetPassword, MainPage } from './pages';

import { getAccessToken } from './utils/token';
import { useUserContext } from './context/useUserContext';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './components/layout';
import HomePage from './pages/home';
import Dashboard from './pages/dashboard';
import EmissionChart from './pages/emission-charts';
import Messages from './pages/messages';
import Settings from './pages/settings';

export default function RoutesIndex() {
  const navigate = useNavigate();
  const { getUser } = useUserContext();

  useEffect(() => {
    const token = getAccessToken();
    if (!token) {
      navigate('/login');
    }
    getUser();
  }, []);

  return (
    <>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/resetPassword/:token" element={<ResetPassword />} />
        <Route path="/" element={<HomePage />} />
        <Route element={<Layout />}>
          <Route path="/main" element={<MainPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/:type" element={<EmissionChart />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
}
