import React, { useMemo, useState } from 'react';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

import Modal from '../../components/modal/Modal';
import classes from './styles.module.scss';
import { IMessage } from '../../consts/types';
import { Button } from '../../components';
import { archiveMessage } from '../../requests/message';

interface IProps {
  show: boolean;
  close: VoidFunction;
  message: IMessage;
}

const MessageView: React.FC<IProps> = ({ show, close, message }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const __html = useMemo(() => {
    if (!message) {
      return '';
    }
    const msg = '<div>' + message.msg;
    return msg.replaceAll('\n', '</div><div>') + '</div>';
  }, [message]);

  const handleArchive = () => {
    setIsLoading(true);
    archiveMessage(message.id)
      .then(() => {
        setIsLoading(false);
        close();
      })
      .catch(() => setIsLoading(false));
  };

  return show ? (
    <Modal
      style={{ width: '90%', maxWidth: '580px' }}
      show={show}
      handleClose={close}
      header={
        <div className={classes['messages__modal__header']}>
          <div className={classes['messages__modal__date']}>
            {message.createDate && format(new Date(message.createDate), 'dd MMMM', { locale: ru })}
          </div>
          <div className={classes['messages__modal__title']}>{message.header}</div>
        </div>
      }
    >
      <div
        className={classes['messages__modal__content']}
        dangerouslySetInnerHTML={{ __html }}
      ></div>
      <div className="df jc-fe">
        <Button
          className={classes['messages__archiveBtn']}
          name="Архивировать"
          variant={'rounded'}
          onClick={handleArchive}
          disabled={isLoading}
        />
      </div>
    </Modal>
  ) : null;
};

export default MessageView;
