import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { X } from 'lucide-react';
import classNames from 'classnames';
import Popover from '../popover';
import Icon from '../icon';
import { getNotificationList, readNotification } from '../../requests/notification';
import { INotification } from '../../consts/types';
import classes from './styles.module.scss';

const NotificationsPopover = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [list, setList] = useState<INotification[]>([]);

  const getParams = () => {
    return {
      page,
      pageSize: 10,
      seen: false,
    };
  };

  useEffect(() => {
    setLoading(true);
    const params = getParams();
    getNotificationList(params)
      .then((res) => {
        setHasMore(res.list.length > 0);
        setList((prev) => [...prev, ...res.list]);
      })
      .catch(() => {
        setHasMore(false);
        setList([]);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, [page]);

  const isHaveUnseenNotification = useMemo(() => {
    return list.length > 0;
  }, [list]);

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
      if (
        (Math.ceil(clientHeight + scrollTop) === scrollHeight ||
          Math.ceil(clientHeight + scrollTop + 1) === scrollHeight) &&
        hasMore &&
        !loading
      ) {
        setPage((prevPage) => prevPage + 1);
      }
    },
    [loading, hasMore],
  );

  const handleRead = (notificationId: number) => () => {
    readNotification(notificationId).then(
      () => {
        const listCopy = [...list];
        const index = listCopy.findIndex((notification) => notification.id === notificationId);
        if (index > -1) {
          listCopy.splice(index, 1);
          setList(listCopy);
        }
      },
      () => null,
    );
  };

  return (
    <Popover
      closeOnClick={false}
      content={
        <div className={classes['notification__list']} onScroll={handleScroll}>
          {list.map((notification, index) => (
            <div
              key={`notification_${index}`}
              className={classNames(
                classes['notification__wrapper'],
                notification.seen && classes['notification__wrapper--seen'],
              )}
            >
              <Icon name={'CircleAlert'} fill={'#FF5A43'} stroke={'#FFFFFF'} size={20} />
              <div className={classes['notification__row']}>
                <div className={classes['notification__title']}>{notification.header}</div>
                <div className={classes['notification__date']}>{notification.createDate}</div>
              </div>
              <div className={classes['notification__row']}>
                <div className={classes['notification__description']}>{notification.msg}</div>
                <div className={classes['notification__value']} title={notification.secondMsg}>
                  {notification.secondMsg}
                </div>
              </div>
              <button type={'button'} onClick={handleRead(notification.id)}>
                <X size={16} />
              </button>
            </div>
          ))}
          {!list.length && <div className={classes['notification__wrapper']}>Нет уведомлений</div>}
        </div>
      }
    >
      <button
        type="button"
        className={classNames(
          classes['layout__notification'],
          isHaveUnseenNotification && classes['layout__notification__alert'],
        )}
      >
        <Icon name={'Bell'} />
        {list?.length > 0 && (
          <Icon name={'CircleAlert'} fill={'#FF5A43'} stroke={'#FFFFFF'} size={16} />
        )}
      </button>
    </Popover>
  );
};

export default NotificationsPopover;
