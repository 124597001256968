import { api } from './api';
import { IMessage, IResponse } from 'src/consts/types';

const baseUrl = '/message';

export const getMessageList = (
  params: Record<string, string | number | number[]>,
): Promise<{ list: IMessage[]; totalSize: number }> => {
  return api.get(`${baseUrl}/list`, { params }).then((res) => res.data);
};

export const archiveMessage = (id: number): Promise<IResponse<null>> => {
  return api.patch(`${baseUrl}/${id}/archive`, {}).then((res) => res.data);
};
