import React, { useState } from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { Button, Checkbox, Icon, Input } from '../../components';
import { useUserContext } from '../../context/useUserContext';
import { changeUserPassword } from '../../requests/user';
import Loader from '../../components/loader';
import classes from './styles.module.scss';

const Settings: React.FC = () => {
  const { user } = useUserContext();
  const [editOn, setEditOn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState<Record<string, boolean>>({});
  const [form, setForm] = useState<{
    password: string;
    newPassword: string;
    newPasswordConfirm: string;
  }>({
    password: '',
    newPassword: '',
    newPasswordConfirm: '',
  });

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const checkNewPassword = (): boolean => {
    const passwordRegex = /^(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return !(form.newPassword !== '' && !passwordRegex.test(form.newPassword));
  };

  const checkNewPasswordConfirm = (): boolean => {
    return !(
      form.newPassword !== '' &&
      form.newPasswordConfirm !== '' &&
      form.newPassword !== form.newPasswordConfirm
    );
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = event.target;
    setIsChecked((prev) => ({ ...prev, [name]: checked }));
  };

  const handleChangePassword = () => {
    if (checkNewPassword() && checkNewPasswordConfirm()) {
      setIsLoading(true);
      changeUserPassword(form.password, form.newPassword)
        .then(() => {
          setEditOn(false);
          setIsLoading(false);
          toast.success('Пароль успешно изменен');
        })
        .catch(({ response }) => {
          setIsLoading(false);
          toast.error(response.data.msg || 'Произошла ошибка, попробуйте еще раз');
        });
    } else {
      toast.error('Проверьте правильность заполнения полей');
    }
  };

  return (
    <Loader isLoading={isLoading}>
      <div className={classes['settings']}>
        <div className={classes['settings__title']}>Настройки</div>
        <div className={classes['settings__list']}>
          <div className={classes['settings__list__item']}>
            <Icon name={'Mail'} size={28} />
            <span>Электронная почта</span>
            <span>{user?.email}</span>
          </div>
          <div
            className={classNames(
              classes['settings__list__item'],
              editOn && classes['settings__list__item--editOn'],
            )}
          >
            <Icon name={'LockKeyhole'} size={28} />
            <span>Пароль</span>
            <span>*******</span>
            {!editOn && (
              <div>
                <Button
                  name="Изменить"
                  variant={'rounded'}
                  icon={'Pencil'}
                  onClick={() => setEditOn(true)}
                />
              </div>
            )}
          </div>
          {editOn && (
            <form autoComplete={'one-time-code'}>
              <div className={classes['settings__changePassword']}>
                <Input
                  variant={'small'}
                  name={'password'}
                  label={'Текущий пароль'}
                  value={form.password}
                  onChange={onChange}
                />
                <div className={classes['settings__changePassword__column']}>
                  <Input
                    type={isChecked.newPassword ? 'text' : 'password'}
                    variant={'small'}
                    name={'newPassword'}
                    label={'Новый пароль'}
                    value={form.newPassword}
                    onChange={onChange}
                    isValid={checkNewPassword()}
                    errorMsg={
                      'Пароль должен содержать не менее 1 символа, 1 заглавной буквы, 1 цифры и 8 символов.'
                    }
                  />
                  <Checkbox
                    id={'newPassword'}
                    name={'newPassword'}
                    label={'Показать пароль'}
                    checked={isChecked.newPassword}
                    onChange={handleChangeCheckbox}
                  />
                </div>
                <div className={classes['settings__changePassword__column']}>
                  <Input
                    type={isChecked.newPasswordConfirm ? 'text' : 'password'}
                    variant={'small'}
                    name={'newPasswordConfirm'}
                    label={'Подтвердите новый пароль'}
                    value={form.newPasswordConfirm}
                    onChange={onChange}
                    isValid={checkNewPasswordConfirm()}
                    errorMsg={'Пароль не совпадает'}
                  />
                  <Checkbox
                    id={'newPasswordConfirm'}
                    name={'newPasswordConfirm'}
                    label={'Показать пароль'}
                    checked={isChecked.newPasswordConfirm}
                    onChange={handleChangeCheckbox}
                  />
                </div>
              </div>
              <div className={classes['settings__changePassword__buttonsRow']}>
                <Button name="Отмена" variant={'transparent'} onClick={() => setEditOn(false)} />
                <Button
                  name="Применить"
                  variant={'rounded'}
                  onClick={handleChangePassword}
                  disabled={isLoading}
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </Loader>
  );
};

export default Settings;
