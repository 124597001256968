import React, { useEffect, useRef, useState } from 'react';
import { DateRange } from 'react-date-range';
import { ru } from 'date-fns/locale';
import { format } from 'date-fns';
import { IDateSelection } from '../../consts/types';
import classes from './styles.module.scss';
import Icon from '../icon';

interface IDateStr {
  startDate: string;
  endDate: string;
}

interface IProps {
  onChange: (range: IDateSelection) => void;
}

const RangePicker = ({ onChange }: IProps) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [didMount, setDidMount] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dateStr, setDateStr] = useState<IDateStr>({
    startDate: '',
    endDate: '',
  });
  const [selectionRange, setSelectionRange] = useState<IDateSelection>({
    key: 'selection',
    startDate: new Date(),
    endDate: new Date(),
  });

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (isOpen && wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, wrapperRef]);

  const handleSelect = (ranges: { selection: IDateSelection }) => {
    setSelectionRange(ranges.selection);
  };

  useEffect(() => {
    if (selectionRange?.startDate) {
      setDateStr((prevState) => ({
        ...prevState,
        startDate: format(selectionRange.startDate, 'dd.MM.yyyy'),
      }));
    }
    if (selectionRange?.endDate) {
      setDateStr((prevState) => ({
        ...prevState,
        endDate: format(selectionRange.endDate, 'dd.MM.yyyy'),
      }));
    }
    if (didMount) {
      onChange(selectionRange);
    }
  }, [selectionRange]);

  const isDateValid = (dateString: string) => {
    const regex = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(\d{4})$/;
    return regex.test(dateString);
  };

  const handleChangeInputValue = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setDateStr((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (isDateValid(value)) {
      fromStrToDate(name as keyof IDateSelection, value);
    }
  };

  const fromStrToDate = (key: keyof IDateSelection, value: string) => {
    try {
      const [d, m, y] = value.split('.');
      const date = new Date(`${m}.${d}.${y}`);
      if (
        (key === 'startDate' && date > selectionRange.endDate) ||
        (key === 'endDate' && date < selectionRange.startDate)
      ) {
        setSelectionRange((prevState) => ({ ...prevState, startDate: date, endDate: date }));
      } else {
        setSelectionRange((prevState) => ({ ...prevState, [key]: date }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleCheckDateStr = (key: keyof IDateStr) => () => {
    if (dateStr[key] && !isDateValid(dateStr[key])) {
      setDateStr((prevState) => ({
        ...prevState,
        [key]: format(selectionRange[key], 'dd.MM.yyyy'),
      }));
    }
  };

  useEffect(() => {
    setDidMount(true);
  }, []);

  return (
    <div className={classes['rangePicker']} ref={wrapperRef}>
      <div className={classes['rangePicker__inputWrapper']}>
        <input
          type="text"
          name="startDate"
          className={classes['rangePicker__input']}
          value={dateStr.startDate}
          onChange={handleChangeInputValue}
          onFocus={() => setIsOpen(true)}
          onBlur={handleCheckDateStr('startDate')}
        />
        <input
          type="text"
          name="endDate"
          className={classes['rangePicker__input']}
          value={dateStr.endDate}
          onChange={handleChangeInputValue}
          onFocus={() => setIsOpen(true)}
          onBlur={handleCheckDateStr('endDate')}
        />
      </div>
      <button
        type="button"
        className={classes['rangePicker__pickerBtn']}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Icon name={'CalendarDays'} size={18} />
      </button>
      {isOpen && (
        <DateRange
          className={classes['rangePicker__calendarWrapper']}
          locale={ru}
          editableDateInputs={true}
          showDateDisplay={false}
          dateDisplayFormat="dd.MM.yyyy"
          staticRanges={[]}
          inputRanges={[]}
          ranges={[selectionRange]}
          onChange={handleSelect}
        />
      )}
    </div>
  );
};

export default RangePicker;
