import React from 'react';
import classes from './styles.module.scss';

interface IProps {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  limit: number;
  totalItems?: number;
}

const Pagination: React.FC<IProps> = ({ page = 0, setPage, totalItems = 0, limit }) => {
  const isLastPage = () => {
    return page === Math.ceil(totalItems / limit) - 1;
  };

  if (!totalItems) {
    return null;
  }

  const getTotalTemplate = () => {
    const begin = page * limit + 1;
    const end = (page + 1) * limit;

    return `${begin} - ${totalItems < end ? totalItems : end} из ${totalItems}`;
  };

  return (
    <div className={classes['pagination']}>
      <button
        type="button"
        className={classes['pagination__previous']}
        onClick={() => setPage(page - 1)}
        disabled={page === 0}
      ></button>
      <button
        type="button"
        className={classes['pagination__next']}
        onClick={() => setPage(page + 1)}
        disabled={isLastPage()}
      ></button>
      <div className={classes['pagination__total']}>{getTotalTemplate()}</div>
    </div>
  );
};

export default Pagination;
