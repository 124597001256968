import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';
import { ru } from 'date-fns/locale';
import { Calendar } from 'src/assets/icons';
import { formatDate, getDates, getRange } from 'src/utils/helpers';
import classes from './styles.module.scss';
import classNames from 'classnames';
import { IDateRange } from 'src/consts/types';
import { addDays } from 'date-fns';

interface Props {
  dates: IDateRange;
  setDates: Dispatch<SetStateAction<IDateRange>>;
  showCalendarIcon?: boolean;
  setSelectedRangeType?: Dispatch<SetStateAction<string>>;
  optionsCustom?: string[];
  dateRangeHide?: boolean;
}

export type DateType = {
  startDate: Date;
  endDate: Date;
  key: string;
};

const options = ['20 мин.', 'День', 'Неделя', 'Месяц', 'Год'];
const dateRangeKey = 'selection';

const SelectDates: React.FC<Props> = ({
  dates,
  setDates,
  showCalendarIcon = true,
  setSelectedRangeType,
  optionsCustom,
  dateRangeHide,
}) => {
  const [selectedRange, setSelectedRange] = useState<string>((optionsCustom || options)[0]);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [tempDateRange, setTempDateRange] = useState<DateType[]>([
    { startDate: new Date(), endDate: addDays(new Date(), 7), key: 'selection' },
  ]);

  const handleChoosePeriod = (item: string) => {
    setSelectedRange(item);
    setSelectedRangeType && setSelectedRangeType(getRange(item));
  };

  const handleRangeChange = (range: any) => {
    setTempDateRange([range[dateRangeKey]]);
    setDates({
      dateFrom: formatDate(range[dateRangeKey]?.startDate),
      dateTo: formatDate(range[dateRangeKey]?.endDate),
    });
  };

  useEffect(() => {
    const dates = getDates(selectedRange);
    setDates({
      dateFrom: dates.startDate,
      dateTo: dates.endDate,
    });
    setTempDateRange([
      { startDate: new Date(dates.startDate), endDate: new Date(dates.endDate), key: 'selection' },
    ]);
  }, [selectedRange]);

  return (
    <div className={classes['wrapper']}>
      <ul className={classes['wrapper__period']}>
        {(optionsCustom || options).map((item: string) => (
          <li
            key={item}
            className={classNames(classes[`option`], classes[`option--${selectedRange === item}`])}
            onClick={() => handleChoosePeriod(item)}
          >
            {item}
          </li>
        ))}
      </ul>
      {!dateRangeHide && (
        <div className={classes['wrapper__options']}>
          <div>
            <span>{new Date(dates.dateFrom).toLocaleDateString('ru-RU')}</span>{' '}
            <span>{new Date(dates.dateTo).toLocaleDateString('ru-RU')}</span>
          </div>
        </div>
      )}
      {showCalendarIcon && (
        <div className={classes['wrapper__calendar']}>
          <div
            className={classes['wrapper__calendar-box']}
            onClick={() => setShowCalendar(!showCalendar)}
          >
            <img src={Calendar} alt="calendar" />
          </div>

          {showCalendar && (
            <div className={classes['calendar-wrapper']}>
              <DateRange
                className={classes['calendar']}
                editableDateInputs={true}
                onChange={(item: any) => handleRangeChange(item)}
                moveRangeOnFirstSelection={false}
                ranges={tempDateRange}
                locale={ru}
                dateDisplayFormat="dd.MM.yyyy"
                startDatePlaceholder=""
                endDatePlaceholder=""
                staticRanges={[]}
                inputRanges={[]}
                months={1}
                direction="horizontal"
                monthDisplayFormat="LLLL yyyy"
                showMonthArrow={true}
                showMonthAndYearPickers={false}
                showSelectionPreview={true}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectDates;
