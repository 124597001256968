import React, { createContext, useContext, useState } from 'react';
import { getCurrentUser } from 'src/requests/user';
import { IResponse, IUser } from 'src/consts/types';
import { getRefreshToken } from 'src/utils/token';

type UserContextType = {
  user: IUser | null;
  getUser: () => void;
  checkRole: (role: string | string[]) => boolean;
};

const initialUserState: IUser = {
  email: '',
  id: 0,
  position: '',
  userPosition: '',
  firstName: '',
  lastName: '',
};

const UserContext = createContext<UserContextType>({
  user: initialUserState,
  getUser: () => null,
  checkRole: () => false,
});

type Props = {
  children: React.ReactNode;
};

export const useUserContext = (): UserContextType => useContext(UserContext);

export const UserProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<IUser | null>(null);

  const getUser = () => {
    getRefreshToken() && getCurrentUser().then((res: IResponse<IUser>) => setUser(res.data));
  };

  const checkRole = (role: string | string[]) => {
    if (!user) {
      return false;
    }

    return Array.isArray(role) ? role.includes(user.userPosition) : role === user.userPosition;
  };

  return <UserContext.Provider value={{ user, getUser, checkRole }}>{children}</UserContext.Provider>;
};
