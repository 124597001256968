import { api } from './api';
import { INotification, IResponse } from 'src/consts/types';

const baseUrl = '/notification';

export const getNotificationList = (
  params: Record<string, string | number | number[] | boolean>,
): Promise<{ list: INotification[]; totalSize: number }> => {
  return api.get(`${baseUrl}/list`, { params }).then((res) => res.data);
};

export const readNotification = (id: number): Promise<IResponse<null>> => {
  return api.get(`${baseUrl}/${id}/read`, {});
};
