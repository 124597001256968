import React from 'react';
import classNames from 'classnames';
import { icons } from 'lucide-react';
import { Icon } from '../index';
import classes from './styles.module.scss';

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactElement;
  name: string;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?:
    | 'primary'
    | 'secondary'
    | 'delete'
    | 'auth'
    | 'small-primary'
    | 'small-delete'
    | 'rounded'
    | 'transparent';
  icon?: keyof typeof icons;
}

const Button: React.FC<IButtonProps> = ({
  name,
  className,
  onClick,
  disabled,
  type = 'button',
  variant = 'primary',
  icon,
  children,
  ...props
}) => {
  return (
    <button
      className={classNames(classes['button'], classes[`button--${variant}`], className)}
      onClick={onClick}
      disabled={disabled}
      type={type}
      {...props}
    >
      {icon && <Icon name={icon} size={24} />}
      {name}
      {children}
    </button>
  );
};

export default Button;
