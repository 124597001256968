import React, { useMemo, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { removeTokens } from 'src/utils/token';
import Popover from '../popover';
import Icon from '../icon';
import { useUserContext } from '../../context/useUserContext';
import NotificationsPopover from './notifications-popover';
import classes from './styles.module.scss';

interface IModule {
  id: number;
  title: string;
  link: string;
  disabled: boolean;
  subModules?: IModule[];
}

const moduleList: IModule[] = [
  {
    id: 1,
    title: 'Водные ресурсы',
    link: '',
    disabled: true,
  },
  {
    id: 2,
    title: 'Атмосферный воздух',
    link: '',
    disabled: true,
  },
  {
    id: 3,
    title: 'АСМ',
    link: '',
    disabled: false,
    subModules: [
      { id: 4, title: 'Выбросы', link: '/main', disabled: false },
      { id: 5, title: 'Сбросы', link: '', disabled: true },
    ],
  },
  {
    id: 6,
    title: 'ОПП',
    link: '',
    disabled: true,
  },
  {
    id: 7,
    title: 'Разрешение на эмиссии',
    link: '',
    disabled: true,
  },
  {
    id: 8,
    title: 'Парниковые газы',
    link: '',
    disabled: true,
  },
  {
    id: 9,
    title: 'Управление проверками',
    link: '',
    disabled: true,
  },
  {
    id: 10,
    title: 'Управление мероприятиями в области ООС',
    link: '',
    disabled: true,
  },
  {
    id: 11,
    title: 'Управление проектами',
    link: '',
    disabled: true,
  },
];

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUserContext();
  const [isDropdownModule, setIsDropdownModule] = useState<boolean>(false);
  const [showSubModules, setShowSubModules] = useState<number>(0);
  const [expandMenu, setExpandMenu] = useState<boolean>(false);
  const modulePopoverRef = useRef<{ close: () => void }>();
  const routes = [
    { name: 'Главная', path: '/main', roles: 'ALL' },
    { name: 'Дэшборд', path: '/dashboard', roles: ['MANAGER', 'MAIN_ECOLOGIST'] },
  ];

  const handleLogOut = () => {
    removeTokens();
    navigate('/login');
  };

  const handleModuleClick = (module: IModule) => {
    if (module.id === showSubModules) {
      setShowSubModules(0);
    } else if (module.subModules) {
      setShowSubModules(module.id);
    } else {
      navigate(module.link);
    }
  };

  const Navigation = useMemo(
    () => (
      <>
        {routes.map((route) => {
          return (
            (route.roles === 'ALL' ||
              (user && Array.isArray(route.roles) && route.roles.includes(user.userPosition))) && (
              <div
                key={route.path}
                onClick={() => {
                  setExpandMenu(false);
                  navigate(route.path);
                }}
                className={classNames(
                  classes['layout__navigation__item'],
                  location.pathname === route.path && classes['layout__navigation__item--active'],
                )}
              >
                {route.name}
              </div>
            )
          );
        })}
        <Popover
          ref={modulePopoverRef}
          position={'bottom-right'}
          closeOnClick={false}
          setIsActive={(value) => setIsDropdownModule(value)}
          content={
            <div className={classes['dropdown__options']}>
              <div
                style={{ padding: '16px 32px', display: 'flex', justifyContent: 'space-between' }}
              >
                <div className={classes['dropdown__options__item']}>МОДУЛИ</div>
                <div
                  style={{ cursor: 'pointer' }}
                  className={classes['cross']}
                  onClick={() => {
                    modulePopoverRef?.current?.close();
                  }}
                />
              </div>
              {moduleList.map((item: IModule) => (
                <React.Fragment key={item.id}>
                  <div
                    className={classNames(
                      classes['dropdown__option-wrap'],
                      classes[`dropdown__option-wrap--${item.disabled && 'disabled'}`],
                    )}
                    style={{ opacity: item.disabled ? 0.5 : 1 }}
                    onClick={() => !item.disabled && handleModuleClick(item)}
                  >
                    <p>{item.title}</p>
                    {item.subModules && <span className={classes[`triangle-down`]}></span>}
                  </div>
                  {showSubModules === item.id &&
                    item.subModules?.map((item) => (
                      <div
                        key={item.id}
                        className={classNames(
                          classes['dropdown__option-wrap2'],
                          classes[`dropdown__option-wrap2--${item.disabled && 'disabled'}`],
                        )}
                        style={{ opacity: item.disabled ? 0.5 : 1 }}
                        onClick={() => !item.disabled && handleModuleClick(item)}
                      >
                        <p>{item.title}</p>
                      </div>
                    ))}
                </React.Fragment>
              ))}
            </div>
          }
        >
          <div
            style={{
              padding: '24px',
              cursor: 'pointer',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
              userSelect: 'none',
            }}
          >
            <span>АСМ-выбросы</span>
            <span className={classes[`triangle-${isDropdownModule ? 'up' : 'down'}`]}></span>
          </div>
        </Popover>
      </>
    ),
    [user, routes, modulePopoverRef, moduleList, showSubModules, isDropdownModule],
  );

  const toggleMobileMenu = () => {
    setExpandMenu((prevState) => !prevState);
  };

  if (!user) return null;

  return (
    <div className={classes['layout']}>
      <div className={classes['layout__header']}>
        <div className="df ai-c">
          {user && (
            <Popover
              content={
                <>
                  <div className={classes['layout__profile__header']}>
                    <span>{user.firstName + ' ' + user.lastName}</span>
                    <span>{user.position}</span>
                  </div>
                  <ul className={classes['layout__profile__menu']}>
                    <li
                      className={classes['layout__profile__menu__item']}
                      onClick={() => navigate('/settings')}
                    >
                      <Icon name={'Settings'} size={18} />
                      Настройки
                    </li>
                    <li
                      className={classes['layout__profile__menu__item']}
                      onClick={() => navigate('/messages')}
                    >
                      <Icon name={'Mail'} size={18} />
                      Мои сообщения
                    </li>
                    <li className={classes['layout__profile__menu__item']} onClick={handleLogOut}>
                      <Icon name={'LogOut'} size={18} />
                      Выход
                    </li>
                  </ul>
                </>
              }
            >
              <div className={classes['layout__profile']}>
                <span>{user.firstName[0] + user.lastName[0]}</span>
              </div>
            </Popover>
          )}
          <NotificationsPopover />
        </div>
        <div className={classes['layout__navigation']}>{Navigation}</div>
        <button
          type="button"
          className={classes['layout__navigation--mobile']}
          onClick={toggleMobileMenu}
        >
          <Icon name={'Menu'} />
        </button>
      </div>
      {expandMenu && (
        <>
          <div className={classes['layout__overlay']} onClick={toggleMobileMenu} />
          <div className={classes['layout__mobileMenu']}>
            <Icon name={'X'} onClick={toggleMobileMenu} />
            {Navigation}
          </div>
        </>
      )}
      <div className={classes['layout__outlet']}>
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
