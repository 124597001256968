import React, { useEffect, useState } from 'react';
import classes from './styles.module.scss';
import LineChart, { IDataset } from 'src/components/charts/line-chart';
import { ICompany, IDateRange, IDivision, ISource } from 'src/consts/types';
import {
  getChartData,
  getCompanySourceList,
  getDivisionsById,
  getEmissionTypes,
} from 'src/requests/source';
import { RedArrow } from 'src/assets/icons';
import Select from 'src/components/select';
import Card from 'src/components/card';
import SelectDates from '../SelectDates';
import { format, set } from 'date-fns';
import { PercentsImg } from 'src/assets/images';

interface IProps {
  companyList: ICompany[];
  firstSelectedCompany?: ICompany;
  firstSelectedSource?: ISource;
  companyDivisionList?: IDivision[];
  showDivisions: boolean;
}

const Diagrams: React.FC<IProps> = ({
  companyList,
  firstSelectedCompany,
  firstSelectedSource,
  companyDivisionList,
  showDivisions,
}) => {
  const [emissionTypes, setEmissionTypes] = useState<string[]>([]);
  const [sourcesList, setSourcesList] = useState<ISource[]>([]);
  const [listType, setListType] = useState<string>('company');

  const [selectedEmissionType, setSelectedEmissionType] = useState<string[]>([]);

  const [selectedCompanies, setSelectedCompanies] = useState<ICompany[]>([]);
  const [selectedSources, setSelectedSources] = useState<ISource[]>([]);

  const [emissionsData, setEmissionsData] = useState<IDataset[]>([]);
  const [wasteConcentrationData, setWasteConcentrationData] = useState<IDataset[]>([]);

  const [selectedRangeType, setSelectedRangeType] = useState<string>('TWENTY_MINUTE');
  const [selectedRange, setSelectedRange] = useState<IDateRange>({
    dateFrom: new Date(format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")).getTime(),
    dateTo: new Date(format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")).getTime(),
  });

  useEffect(() => {
    setListType(showDivisions ? 'division' : 'company');
    setSelectedCompanies([]);
    setSelectedSources([]);
  }, [showDivisions]);

  const [xLabels, setXLabels] = useState<string[]>([]);

  const handleFilterClick = (item: string) => {
    if (selectedEmissionType.includes(item)) {
      setSelectedEmissionType(selectedEmissionType.filter((i) => i !== item));
    } else {
      setSelectedEmissionType([...selectedEmissionType, item]);
    }
  };

  const getColor = (index: number) => {
    switch (index) {
      case 1:
        return ['#455BDC', '#5053c3', '#5f57a9', '#645890', '#605476'];
      case 2:
        return ['#20DFB1', '#30c6b3', '#3bacac', '#418992', '#426c79'];
      case 3:
        return ['#FF5A63', '#e67068', '#cc8271', '#b28775', '#998373'];
      case 4:
        return ['#FFC258', '#e6c666', '#ccc06f', '#b2b273', '#959972'];
      default:
        return ['#AE2AFF', '#ba3de6', '#ba4acc', '#b153b2', '#995692'];
    }
  };

  useEffect(() => {
    firstSelectedCompany && setSelectedCompanies([firstSelectedCompany]);
    firstSelectedSource && setSelectedSources([firstSelectedSource]);
  }, [firstSelectedCompany, firstSelectedSource]);

  useEffect(() => {
    if (selectedCompanies?.length > 0) {
      if (listType === 'company') {
        getCompanySourceList(selectedCompanies.map((company) => company.id)).then((res) => {
          setSourcesList(
            res.list
              .map((item) => ({ ...item, label: item.name, name: item.name }))
              .sort((a, b) => a.name.localeCompare(b.name)),
          );
        });
      } else {
        getDivisionsById(selectedCompanies.map((company) => company.id)).then((res) => {
          setSourcesList(
            res.list
              .map((item: any) => ({ ...item, label: item.name, name: item.name }))
              .sort((a: any, b: any) => a.name.localeCompare(b.name)),
          );
        });
      }
    }
  }, [selectedCompanies]);

  useEffect(() => {
    getEmissionTypes().then((res) => {
      setEmissionTypes(res.list);
      setSelectedEmissionType([res.list[0]]);
    });
  }, []);

  useEffect(() => {
    if (selectedSources?.length > 0 && selectedCompanies?.length > 0 && selectedEmissionType) {
      const colors = selectedSources.map((item, index) => ({
        title: `${item.name}`,
        color: getColor(index),
      }));
      getChartData(
        selectedSources.map((source) => source.id),
        selectedEmissionType,
        selectedRangeType,
      ).then((res) => {
        const processedEmissionsData: IDataset[] = [];
        const processedWasteConcentrationData: IDataset[] = [];
        let xLabelsArrays: string[] = [];
        res.list.forEach((item) => {
          const color = colors.find((c) => c.title === item.sourceName)?.color || '';

          const emissionsChart = item.typeCharts.map((chart) =>
            chart.charts.find((c) => c.parameterKey === 'Emissions'),
          );

          const wasteConcentrationChart = item.typeCharts.map((chart) =>
            chart.charts.find((c) => c.parameterKey === 'WasteConcentration'),
          );

          const emissionsDataArray = emissionsChart
            ? emissionsChart.map((chart) => chart && chart.param.map((p) => p.value))
            : [];

          xLabelsArrays = emissionsChart ? emissionsChart[0]?.param.map((p) => p.key) || [] : [];

          const wasteConcentrationDataArray = wasteConcentrationChart
            ? wasteConcentrationChart.map((chart) => chart && chart.param.map((p) => p.value))
            : [];

          emissionsDataArray.forEach((data, index) => {
            processedEmissionsData.push({
              data: data as number[],
              borderColor: color[index],
              backgroundColor: color[index],
              fill: true,
              tension: 0.4,
              label: `${item.typeCharts[index].type} ${item.sourceName}`,
            });
          });

          wasteConcentrationDataArray.forEach((data, index) => {
            processedWasteConcentrationData.push({
              data: data as number[],
              borderColor: color[index],
              backgroundColor: color[index],
              fill: true,
              tension: 0.4,
              label: `${item.typeCharts[index].type} ${item.sourceName}`,
            });
          });

          if (emissionsChart) {
            wasteConcentrationDataArray.forEach((data, index) => {
              if (data && emissionsChart !== undefined && emissionsChart[index] !== undefined) {
                processedEmissionsData.push({
                  data: Array(data.length).fill(emissionsChart[index]?.norma ?? 0),
                  borderColor: `${color[index]}80`,
                  backgroundColor: `${color[index]}80`,
                  fill: false,
                  tension: 0.4,
                  borderDash: [15, 15],
                  label: `Норма, ${item.typeCharts[index].type} ${item.sourceName}`,
                });
              }
            });
          }
          if (wasteConcentrationChart) {
            wasteConcentrationDataArray.forEach((data, index) => {
              if (
                data &&
                wasteConcentrationChart !== undefined &&
                wasteConcentrationChart[index] !== undefined
              ) {
                processedWasteConcentrationData.push({
                  data: Array(data.length).fill(wasteConcentrationChart[index]?.norma ?? 0),
                  borderColor: `${color[index]}80`,
                  backgroundColor: `${color[index]}80`,
                  fill: false,
                  tension: 0.4,
                  borderDash: [15, 15],
                  label: `Норма, ${item.typeCharts[index].type} ${item.sourceName}`,
                });
              }
            });
          }
        });

        setXLabels(xLabelsArrays);
        setEmissionsData(processedEmissionsData);
        setWasteConcentrationData(processedWasteConcentrationData);
      });
    } else {
      setXLabels([]);
      setEmissionsData([]);
      setWasteConcentrationData([]);
    }
  }, [selectedEmissionType, selectedSources, selectedRangeType, selectedCompanies]);

  const handleChangeList = (type: string) => {
    setListType(type);
    setSelectedCompanies([]);
    setSelectedSources([]);
  };

  useEffect(() => {
    if (selectedCompanies.length === 0) {
      setSelectedSources([]);
    }
  }, [selectedCompanies]);

  return (
    <>
      <div className={classes['grid2']}>
        <div className={classes['flex']}>
          <Select
            label="Предприятия"
            secondLabel="Дивизион"
            options={listType === 'company' ? companyList : companyDivisionList}
            handleSelect={setSelectedCompanies}
            selectedOptions={selectedCompanies}
            isMultiple={true}
            maxLength={5}
            listType={listType}
            handleChangeList={handleChangeList}
            contentSize={5}
            minWidth="320px"
            minHeight="76px"
          />
          <Select
            sources
            label="Источники"
            options={sourcesList}
            handleSelect={setSelectedSources}
            selectedOptions={selectedSources}
            isMultiple={true}
            maxLength={5}
            minWidth="220px"
            minHeight="76px"
          />
        </div>
        <ul className={classes['emission-types']} style={{ marginTop: 30 }}>
          {emissionTypes?.map((item: string) => (
            <li
              key={item}
              onClick={() => handleFilterClick(item)}
              className={`${classes[selectedEmissionType.includes(item) ? 'checked' : '']} ${
                classes['emission-type']
              }`}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
      <SelectDates
        dates={selectedRange}
        setDates={setSelectedRange}
        showCalendarIcon={false}
        setSelectedRangeType={setSelectedRangeType}
      />
      <div className={classes['grid']}>
        <div className={classes['cards-row']}>
          <Card>
            <LineChart
              datasets={emissionsData}
              chartName={'Выбросы ЗВ, г/с'}
              measurementUnit={'г.'}
              xLabels={xLabels}
            />
          </Card>
          <Card>
            <LineChart
              datasets={wasteConcentrationData}
              chartName={'Концентрация ЗВ, мг/нм3'}
              measurementUnit={'мг.'}
              xLabels={xLabels}
            />
          </Card>
        </div>
        <div className={classes['cards-row']}>
          <Card>
            <LineChart
              datasets={[
                {
                  data: [],
                  borderColor: '',
                  backgroundColor: '',
                  fill: false,
                  tension: 0.4,
                },
              ]}
              chartName={'Объем газовоздушной смеси, нм3/с'}
              measurementUnit={'нм3.'}
              xLabels={xLabels}
            />
          </Card>
          <div className={classes['grid__cards']}>
            <Card>
              <div className={classes['card']}>
                <div
                  className={classes['card__number']}
                  style={{
                    display: 'flex',
                    gap: 12,
                    margin: 'auto',
                  }}
                >
                  <div>15,</div>
                  <div>
                    <div className={classes['card__C']}>С</div>
                    <span className={classes['card__gradus']}>O</span>
                  </div>
                </div>
                <div className={classes['card__text-wrapper']}>
                  <div
                    className={classes['card__temp']}
                    style={{
                      gap: 0,
                    }}
                  >
                    Температура, C<span className={classes['card__gradus2']}>O</span>
                  </div>
                  <div
                    style={{
                      gap: 19,
                      alignItems: 'center',
                    }}
                  >
                    <img src={RedArrow} alt="img" />
                    <span className={classes['card__red']}>
                      на 12 <span className={classes['card__gradus3']}>O </span>С выше нормы
                    </span>
                  </div>
                </div>
              </div>
            </Card>
            <Card>
              <div className={classes['card']}>
                <div
                  style={{
                    margin: 'auto',
                  }}
                >
                  <img src={PercentsImg} alt="circle" />
                </div>
                <div className={classes['card__text-wrapper']}>
                  <p
                    className={classes['card__text']}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      minHeight: 40,
                    }}
                  >
                    Концентрация кислорода
                  </p>
                  <div
                    style={{
                      gap: 19,
                      alignItems: 'center',
                    }}
                  >
                    <img src={RedArrow} alt="img" />
                    <span className={classes['card__red']}>на 37% выше нормы </span>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Diagrams;
