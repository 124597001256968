const getOrCreateTooltip = (chart: any) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const externalTooltipHandler = (context: any, data: { name: string; value: number }[]) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  if (tooltip.body) {
    const tableHead = document.createElement('thead');

    data
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      .forEach((item) => {
        const tr = document.createElement('tr');
        (tr as any).style.borderWidth = 0;
        (tr as any).style.color = '#000';

        const th = document.createElement('th');
        (th as any).style.borderWidth = 0;
        (th as any).style.color = '#000';

        const progressBarHTML =
          '<div style="max-width: 70px;overflow: hidden;  color: #38387433; letter-spacing: 5px;">___________________________________________________</div>';

        th.innerHTML = `<div style="min-width: fit-content; display: flex; justify-content: space-between; align-items: center;"><div style="font-weight: 700; font-size: 24px; color: #383874; width: fit-content;">${item.name}</div>${progressBarHTML}<div style="font-weight: 700; font-size: 24px; color: #383874; min-width: fit-content;">${item.value} т</div></div>`;

        tr.appendChild(th);
        tableHead.appendChild(tr);
      });

    const tableRoot = tooltipEl.querySelector('table');

    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    tableRoot.appendChild(tableHead);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  tooltipEl.style.background = '#fff';
  tooltipEl.style.boxShadow = '0px 31px 25px 0px #2934951F';
  tooltipEl.style.opacity = 1;
  tooltipEl.style.width = 'fit-content';
  tooltipEl.style.height = 'fit-content';
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = '30px';
  tooltipEl.style.zIndex = '1000';
};
